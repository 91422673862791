(function ($) {

    $(document).ready(decorateLinks);

    function parseQuery(queryString) {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    function decorateLinks() {

        var links = $('a:not(.no-link-decorator)');
        var querystring = window.location.search.replace(/\?/, '');

        if (querystring) {

            var queryParObject = parseQuery(querystring);

            for (var i = 0; i < links.length; i++) {

                var target = links[i];

                if (target && target.nodeName === 'A') {

                    var href = target.getAttribute('href');

                    if (href) {

                        if (href.indexOf('/') === 0 || href.indexOf('petwise-insurance') !== -1) {  // only relative links or inside the mobilers domain

                            var newhref = href.slice(0, href.length);
                            if ($(target).hasClass('link-decorator-subst')) {
                                for (var property in queryParObject) {
                                    var key = "#!" + property;
                                    var value = queryParObject[property];
                                    newhref = newhref.replace(key, value);
                                }
                            } else {
                                if (href.indexOf(querystring) === -1) {
                                    newhref += (/\?/.test(href) ? '&' : '?') + querystring;
                                }
                            }

                            target.setAttribute('href', newhref);
                        }
                    }
                }
            }
        }
    }

})(jQuery);